import React from 'react';
import loadable from '@loadable/component';

const Seo = loadable(() => import('../components/Seo'));
const AnimationRevealPage = loadable(() => import('../components/AnimationRevealPage'));
const ContactUs = loadable(() => import('../components/ContactForm'));
const Layout = loadable(() => import('../components/Layout'));

export default function Contact() {
  return (
    <Layout>
      <Seo
        title="Nous contacter"
        description="N'hésitez pas à nous contacter pour toutes questions ou demande"
      />
      <AnimationRevealPage>
        {/* <div className="flex items-center justify-center">
          <StaticImage
            src="../images/undraw_contact_us_-15-o2.svg"
            quality={95}
            placeholder="tracedSVG"
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Otas Lab - Nous contacter"
            className="mt-36 object-cover object-center h-auto w-full md:w-1/2 mb-2"
            loading="eager"
          />
        </div> */}

        <ContactUs />
      </AnimationRevealPage>
    </Layout>
  );
}
